// -------------------------------------
// Buton styling
// -------------------------------------

.btn {

    padding: 0 1.4rem;
    border-radius: 0;
    box-shadow: none;
    text-transform: none;
    font-size: 1rem;
    font-weight: 500;
    line-height: 2.5rem;
    padding: 0 1.4rem;
    height: 2.56rem;
    background-color: $btn-default-bg-color;

    @media #{$medium-and-up} {

    line-height: 3rem;
    height: 3.06rem;
    font-size: 1.4rem;
    }


    &:focus {
        background-color: $btn-default-bg-color;
    }

    &:hover {
        background-color: $btn-default-bg-color;
        box-shadow: none;
    }


    &.btn-light, .dark & {    
        color:  $btn-light-color;    
        background-color: $btn-light-bg-color;
        
        &:focus {
            background-color: $btn-light-bg-color-focus;
        }

        &:hover {
            background-color: $btn-light-bg-color-hover;
            box-shadow: none;
        }

        &.btn-border {
            border: solid $btn-border-width $btn-light-btn-border-border-color;
            background-color: $btn-light-btn-border-bg-color;
            color: $btn-light-btn-border-text-color;
        }

        &.btn-arrow {
            &::before {
                background-color: $btn-light-arrow-angle-color;
            }

            &::after {
                border-left: solid 5px $btn-light-arrow-seperator-color;   
            }    
            
            i{
                border-left: solid 5px $btn-light-arrow-seperator-color;  
            }
        }
    }

    &.btn-dark, .light &, .blank &, .infobox & {
        
        background-color: $btn-dark-bg-color;

        &:focus {
            background-color: $btn-dark-bg-color-focus;
        }

        &:hover {
            background-color: $btn-dark-bg-color-hover;
            box-shadow: none;
        }

        &.btn-border {
            border: solid $btn-border-width $btn-dark-btn-border-border-color;
            background-color: $btn-dark-btn-border-bg-color;
            color: $btn-dark-btn-border-text-color;
        }

        &.btn-arrow {
            &::before {
                background-color: $btn-dark-arrow-angle-color;
            }

            &::after {
                border-left: solid 6px $btn-dark-arrow-seperator-color;   
            }
            i{
                border-left: solid 6px $btn-dark-arrow-seperator-color;  
                padding: 1px 0 0;
                @media #{$medium-and-up} {

                    padding: 2px 0 0;
                }
            }

            color: $btn-dark-arrow-color;         
        }

    }

    .infobox &, .blank & {


        &.btn-arrow {       

            &::after {
                border-left: solid 5px $btn-arrow-seperator-color;   
            }
            i{
                border-left: solid 5px $btn-arrow-seperator-color;  
            }
              
        }
    }

    &.btn-dark-read-more {
        color: $btn-dark-read-more-color;
        background-color: $btn-dark-read-more-bg-color;

        &:focus {
            background-color: $btn-dark-read-more-bg-color-focus;
        }

        &:hover {
            background-color: $btn-dark-read-more-bg-color-hover;
            box-shadow: none;
        }

        &.btn-border {
            border: solid $btn-border-width $btn-dark-read-more-btn-border-border-color;
            background: $btn-dark-read-more-btn-border-bg-color;
            color: $btn-dark-read-more-btn-border-text-color;
        }
        

    }

    &.btn-light-read-more {
        color: $btn-light-read-more-color;
        background-color: $btn-light-read-more-bg-color;

        &:focus {
            background-color: $btn-light-read-more-bg-color-focus;
        }

        &:hover {
            background-color: $btn-light-read-more-bg-color-hover;           
        }
    }

    &.alternative{
        color: $btn-alternative-color;
        background-color: $btn-alternative-bg-color;

        &:focus {
            background-color: $btn-alternative-bg-color-focus;
        }

        &:hover {
            background-color: $btn-alternative-bg-color-hover;           
        }
    }

    &.btn-arrow.btn-light-read-more {
        &::before {
            background-color: $btn-read-more-arrow-angle-color;
        }

        &::after {
            border-left: solid 5px $btn-read-more-arrow-seperator-color;
        }
    }



    &.btn-arrow {

        position: relative;
        padding-right: 72px;
        min-width: 15rem;

        i{
            position: absolute;
            top: 0;
            right: 0;
            border-left: solid 5px $btn-arrow-seperator-color;
            border-bottom: none !important;
            padding: 4px 0px 0 0px;
            font-size: 2.5em;
            height: 100%;
        }

        @media #{$large-and-up} {
            i{
                padding: 3px 0px 0 0px;
            }
        }

        &::before {
            //content: '';
            //display: inline-block;
            //position: absolute;
            //top: 9px;
            //right: 15px;
            //width: 20px;
            //height: 1.8rem;
            //background-color: $btn-arrow-angle-color;
            //mask-size: contain;
            //mask: url('/assets/dist/svg/angle-right-bold.png') no-repeat 100% 100%;
        }

        &::after {
            //content: '';
            //display: inline-block;
            //position: absolute;
            //top: 0;
            //right: 3.2rem;
            //width: 1px;
            //height: 50px;
            //border-left: solid 5px $btn-arrow-seperator-color;
        }

        &.btn-back{
            min-width:1px;
            padding-right: 2rem;
            i{
                border-left:0;
            }

            @media #{$small-and-down} {
                padding-right: 1.4rem;
            }
        }
    }

    &.btn-dark.btn-arrow, .light &.btn-arrow, .blank &.btn-arrow{
        &::before {
            //background-color: $btn-light-arrow-angle-color;
        }

        &::after {
            //border-left: solid 5px $btn-light-arrow-seperator-color;
        }
    }

    &.btn-small {

        height: 2.4rem;
        padding: 0 1.4rem;
        font-size:  1.333rem;
        line-height: 2.5rem;
        font-weight: 400;
        min-width: 11rem;
        &.btn-arrow {

            padding-right: 53px;

            &::before {
                top: 5px;
                right: 8px;
                width: 20px;
                height: 20px;
            }

            &::after {
                right: 30px;
                height: 30px;
                border-left: solid 3px $btn-arrow-seperator-color;
            }

        }

        &.btn-border {
            line-height: 2.2rem;
        }


    }
    
    &.btn-check {
        min-width: 13.8rem;
        position: relative;
        padding-right: 50px;

        .check {

            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            width: 2.8rem;
            height: 2.4rem;
            border-left: solid 5px $btn-checkbox-seperator-color;
            background: $btn-checkbox-background-color;

            box-shadow: 0 0 0 3px $btn-checkbox-check-border-color inset;

            &::after {
                content: '';
                display: block;
                width: 1.53rem;
                min-height: 1.53rem;
                margin: .47rem; 
                background: $btn-checkbox-check-bg-color; 
            }

        }

        &.checked .check {
            &::after {
                background: $btn-checkbox-check-color;
                mask: url('/assets/dist/svg/check.svg') no-repeat 100% 100%;
                mask-size: contain;
            }
        }

    }

}

@media only screen and (min-width: 993px){
    .btn-arrow.btn-large i, .btn.btn-arrow i {
        padding: 2px 0 0;
    }
}

@media only screen and (min-width: 993px){
    .btn-arrow.btn-large i, .btn.btn-arrow i {
        padding: 2px 0 0;
    }
}
@media #{$small-and-down} {
    .btn-arrow.btn-large i, .btn.btn-arrow i {
        padding: 1px 0 0;
    }
}


// -------------------------------------
// Badges styling
// -------------------------------------

.app-badge {
    margin-right:1.3rem;
}
