form {
    .infobox &{
        padding: 0 2rem;
    }
    //border: solid 1px $form-border-color;

    .input-field--submit {
        margin-top: 1.5rem;
    }

}

input.input[type="text"] {
    border: solid 1px $form-fields-border-color;
}

form label, form .input-field label{
    color: $color-primary-dark;
}

form .input-field label.indent{
    margin-left:1.7rem;
}

input:not([type]),
input[type=date]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=search]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=text]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=url]:not(.browser-default),
textarea.materialize-textarea {
    color: $form-input-text-color;

    .infobox &{
        margin: 0;
        border: solid 1px $form-fields-border-color;
        text-indent: 1rem;
    }
}

input:not([type]),
input[type=email]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=search]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=text]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=url]:not(.browser-default),
textarea.materialize-textarea {
    font-size: 1.2rem;
    .infobox &{
        text-indent: 1.7rem;
    }
}



.select-wrapper.valid>input.select-dropdown,
input.valid:not([type]),
input.valid:not([type]):focus,
input[type=date].valid:not(.browser-default),
input[type=date].valid:not(.browser-default):focus,
input[type=datetime-local].valid:not(.browser-default),
input[type=datetime-local].valid:not(.browser-default):focus,
input[type=datetime].valid:not(.browser-default),
input[type=datetime].valid:not(.browser-default):focus,
input[type=email].valid:not(.browser-default),
input[type=email].valid:not(.browser-default):focus,
input[type=number].valid:not(.browser-default),
input[type=number].valid:not(.browser-default):focus,
input[type=password].valid:not(.browser-default),
input[type=password].valid:not(.browser-default):focus,
input[type=search].valid:not(.browser-default),
input[type=search].valid:not(.browser-default):focus,
input[type=tel].valid:not(.browser-default),
input[type=tel].valid:not(.browser-default):focus,
input[type=text].valid:not(.browser-default),
input[type=text].valid:not(.browser-default):focus,
input[type=time].valid:not(.browser-default),
input[type=time].valid:not(.browser-default):focus,
input[type=url].valid:not(.browser-default),
input[type=url].valid:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus {
    color: $form-input-text-color;
    border-bottom-width: 1px;
    border-color: $form-fields-focus-border-color;
}



.select-wrapper.invalid>input.select-dropdown,
input.invalid:not([type]),
input.invalid:not([type]):focus,
input[type=date].invalid:not(.browser-default),
input[type=date].invalid:not(.browser-default):focus,
input[type=datetime-local].invalid:not(.browser-default),
input[type=datetime-local].invalid:not(.browser-default):focus,
input[type=datetime].invalid:not(.browser-default),
input[type=datetime].invalid:not(.browser-default):focus,
input[type=email].invalid:not(.browser-default),
input[type=email].invalid:not(.browser-default):focus,
input[type=number].invalid:not(.browser-default),
input[type=number].invalid:not(.browser-default):focus,
input[type=password].invalid:not(.browser-default),
input[type=password].invalid:not(.browser-default):focus,
input[type=search].invalid:not(.browser-default),
input[type=search].invalid:not(.browser-default):focus,
input[type=tel].invalid:not(.browser-default),
input[type=tel].invalid:not(.browser-default):focus,
input[type=text].invalid:not(.browser-default),
input[type=text].invalid:not(.browser-default):focus,
input[type=time].invalid:not(.browser-default),
input[type=time].invalid:not(.browser-default):focus,
input[type=url].invalid:not(.browser-default),
input[type=url].invalid:not(.browser-default):focus,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea.invalid:focus,
.has-error input.invalid:not([type]),
.has-error input:not([type]):focus,
.has-error input[type=date]:not(.browser-default),
.has-error input[type=date]:not(.browser-default):focus,
.has-error input[type=datetime-local]:not(.browser-default),
.has-error input[type=datetime-local]:not(.browser-default):focus,
.has-error input[type=datetime]:not(.browser-default),
.has-error input[type=datetime]:not(.browser-default):focus,
.has-error input[type=email]:not(.browser-default),
.has-error input[type=email]:not(.browser-default):focus,
.has-error input[type=number]:not(.browser-default),
.has-error input[type=number]:not(.browser-default):focus,
.has-error input[type=password]:not(.browser-default),
.has-error input[type=password]:not(.browser-default):focus,
.has-error input[type=search]:not(.browser-default),
.has-error input[type=search]:not(.browser-default):focus,
.has-error input[type=tel]:not(.browser-default),
.has-error input[type=tel]:not(.browser-default):focus,
.has-error input[type=text]:not(.browser-default),
.has-error input[type=text]:not(.browser-default):focus,
.has-error input[type=time]:not(.browser-default),
.has-error input[type=time]:not(.browser-default):focus,
.has-error input[type=url]:not(.browser-default),
.has-error input[type=url]:not(.browser-default):focus,
.has-error textarea.materialize-textarea,
.has-error textarea.materialize-textarea:focus {
    border-bottom-width: 1px;
    border-color: $form-fields-invalid-border-color;
}

.select-wrapper{
   // margin: 0 0 40px 0;
}


input:not([type]):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid $form-fields-focus-border-color;
    box-shadow: 0 1px 0 0 $form-fields-focus-border-color;
}

input:not([type]):focus:not([readonly]) + label, input[type=text]:not(.browser-default):focus:not([readonly]) + label, input[type=password]:not(.browser-default):focus:not([readonly]) + label, input[type=email]:not(.browser-default):focus:not([readonly]) + label, input[type=url]:not(.browser-default):focus:not([readonly]) + label, input[type=time]:not(.browser-default):focus:not([readonly]) + label, input[type=date]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime]:not(.browser-default):focus:not([readonly]) + label, input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label, input[type=tel]:not(.browser-default):focus:not([readonly]) + label, input[type=number]:not(.browser-default):focus:not([readonly]) + label, input[type=search]:not(.browser-default):focus:not([readonly]) + label, textarea.materialize-textarea:focus:not([readonly]) + label {
    color: $form-fields-focus-label-color;
}

.has-error input:not([type]),
.has-error input[type=text]:not(.browser-default),
.has-error input[type=password]:not(.browser-default),
.has-error input[type=email]:not(.browser-default),
.has-error input[type=url]:not(.browser-default),
.has-error input[type=time]:not(.browser-default),
.has-error input[type=date]:not(.browser-default),
.has-error input[type=datetime]:not(.browser-default),
.has-error input[type=datetime-local]:not(.browser-default),
.has-error input[type=tel]:not(.browser-default),
.has-error input[type=number]:not(.browser-default),
.has-error input[type=search]:not(.browser-default),
.has-error textarea.materialize-textarea{
    margin:0;
}
.has-error{
    margin-bottom: 20px;
}

.input-field.required:before{
    .infobox &{
        content: "*";
    }
    position: absolute;
    left: 0;
    top: 1.5rem;
    margin-top: -0.8rem;
    font-size: 1.2rem;
    margin-left: 1rem;
}

[type=checkbox]:checked+label:before {
    border-right: 2px solid $form-field-checkbox-border-color;
    border-bottom: 2px solid $form-field-checkbox-border-color;
}


[type=checkbox]+label  {
    line-height: 21px;
    font-size:1.067rem;
    padding-left: 30px;
}

[type=checkbox]+label:before, [type=checkbox]:not(.filled-in)+label:after{
    border: 1px solid $form-field-checkbox-border-color;
    border-radius: 0px;
    width: 20px;
    height: 20px;
}
[type=checkbox] + label{
    pointer-events: all;
    transform: translateY(0px);
}

[type=radio] + label {
    pointer-events: all;
}

textarea.materialize-textarea, .infobox textarea.materialize-textarea{
    padding: .7rem 1.7rem;
    text-indent: 0;
    box-sizing: border-box;
}

.input-field ul{

    margin: 6px 0;
    li.error{
        color: #fd0909;
    }
}

.form-checkbox-container {
    margin-bottom: 20px;
}

.dropdown-content li>a, .dropdown-content li>span{
    color: $color-primary-dark;
}
.input-field--submit .btn {
    min-width: auto;
    padding: 0 1.4rem 0 1.4rem;
    height: 46px;
}
.input-field--submit .btn.btn-arrow{
    padding: 0 95px 0 1.4rem;
    height: 46px;
    min-width: 15rem;
}


.radio-wrapper{
    label{
        transform: translateY(0px);
    }
}

#sonata_basket_payment_paymentMethod{
    display:block;
    overflow:hidden;
    .radio-wrapper{
        float:left;
        display: block;
    }
}

.materialize-choice_widget_expanded{
    padding-top:25px;
    padding-top: 1.5rem;
    + label{
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(0px);
    }
}

.page-section.infobox .container  .form-infobox {
    padding: .5rem 2rem;
    background-color: $form-infobox-bg-color;
    color: $form-infobox-text-color;

    p {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        line-height: 30px;
    }

    .info {
        width: 25px;
        height: 25px;
        margin-bottom: -3px;
        fill: currentColor;
        color: $form-infobox-icon-color;
    }

}

form a{
    color:$color-primary-tint-4;
    font-size:1.067rem;
}

form .information{
    background-color:$form-information-bg-color;
    padding: 0.6667rem;
    color:$form-information-text-color;
    display: inline-block;
    border-radius: 10px;
    margin-top: 0.7em;
    p{
        margin:0;
        max-width:100%;
    }
    &.spacing{
        margin-bottom:20px;
    }
}

.infobox{
    .input-field label:not(.label-icon).active {
        -webkit-transform: translateX(-1.7rem) translateY(-14px) scale(0.8);
        transform: translateX(-1.7rem) translateY(-14px) scale(0.8);

    }
}

.picker--opened{
    z-index: 99999999;
}

@media (min-height: 38.875em){

    .picker--opened .picker__frame {
        top: 20%;
        bottom: auto;
    }
}

section.detail-page {
    .content {
        .profileForm {

            .vich-image {
                display: flex;
                justify-content: flex-end;
                height: 118px;
                padding-left: 115px;

                .file-field {
                    width: 100%;
                    order: 2;
                    align-self: center;
                    margin-top: -15px;

                    input {
                        margin: 0;
                    }

                    .file-path-wrapper {
                        padding-left: 0;
                    }
                }

                & + label {
                    font-size: 1.333rem;
                    margin-left: 115px;
                    margin-top: -18px;
                }

                a {
                    width: 100px;
                    margin-right: 2%;
                    height: 100px;
                    z-index: 2;
                    left: 0;
                    position: absolute;
                    background: #FFF;
                    border: 1px solid $color-primary-white;

                    img {
                        max-width: 100%;
                        height: 100% !important;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                &:before {
                    content: "";
                    background-image: url($blanco-profile-image-url);
                    width: 13%;
                    max-width: 100px;
                    height: 100px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: left center;
                    position: absolute;
                    left: 0;
                }

                .form-checkbox-container {
                    position: absolute;
                    bottom: -10px;
                }

                @media screen and (max-width: 700px) {
                    display: block;
                    height: auto;
                    padding-top: 140px;
                    padding-bottom: 43px;
                    padding-left: 0;
                    &:before {
                        width: 100%;
                        top: 20px;
                        height: 96px;
                    }

                    .file-field {
                        text-align: center;
                        width: 100%;

                        .btn {
                            display: inline-block;
                            float: none;
                        }
                    }
                    & + label {
                        margin-left: 0;
                    }
                    &:before {
                        background-position: center;
                    }
                    a {
                        display: block;
                        width: 100%;
                        top: 20px;
                        position: absolute;
                        text-align: center;

                        img {
                            height: 100px;
                            width: 100px;
                        }
                    }
                }

            }


        }
    }
}

.select-dropdown{
    padding:0!important;
    li{
        list-style: none!important;
        margin:0!important;
    }
}

.content form .input-field.has-error{
    ul{
        margin:0;
        padding:0;
        li{
            list-style: none;
            font-size: 1.0rem;
            margin:0;
            padding:0;
        }

    }
}
