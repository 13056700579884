// -------------------------------------
//   Infobox section styling
// -------------------------------------

.page-section.infobox {

    .container {

        // padding: 3rem 0;
        position: relative;

        p {
            font-size: 1.4rem;
        }

        .triangle-1 {
            @include triangle-north-east($infobox-trianble-1-bg-color, 50px);
            position: absolute;
            z-index: 1;
            top: -96px;
            left: 56px;
        }

        .triangle-2 {
            @include triangle-south-east($infobox-trianble-2-bg-color, 50px);
            position: absolute;
            z-index: 1;
            right: -7px;
            bottom: -102px;
        }

    }

}
