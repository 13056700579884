// -------------------------------------
// Orientation helper mixins
// -------------------------------------

// North orientation
@mixin tri-orientation-n {
    transform: rotate(270deg);
}

// North-East orientation
@mixin tri-orientation-ne {
    transform: rotate(315deg);
}

// East orientation
@mixin tri-orientation-e {
    transform: rotate(0deg);
}

// South-East orientation
@mixin tri-orientation-se {
    transform: rotate(45deg);
}

// South orientation
@mixin tri-orientation-s {
    transform: rotate(90deg);
}

// South-West orientation
@mixin tri-orientation-sw {
    transform: rotate(135deg);
}

// West orientation
@mixin tri-orientation-w {
    transform: rotate(180deg);
}

// North-West orientation
@mixin tri-orientation-nw {
    transform: rotate(225deg);
}




// -------------------------------------
// Main triangle generator mixin
// -------------------------------------
@mixin triangle($color: $color-primary-white, $size: 150px, $orientation: 'north-east') {
    width: 0;
    height: 0;
    display: inline-block;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;

    @if $orientation == 'north' {
        @include tri-orientation-n();
    } @else if $orientation == 'north-east' {
        @include tri-orientation-ne();
    } @else if $orientation == 'east' {
        @include tri-orientation-e();
    } @else if $orientation == 'south-east' {
        @include tri-orientation-se();
    } @else if $orientation == 'south' {
        @include tri-orientation-s();
    } @else if $orientation == 'south-west' {
        @include tri-orientation-sw();
    } @else if $orientation == 'west' {
        @include tri-orientation-w();
    } @else if $orientation == 'north-west' {
        @include tri-orientation-nw();
    }

}




// -------------------------------------
// Helper mixins for shorter usages
// -------------------------------------

// North orientation
@mixin triangle-north($color: $color-primary-white, $size: 150px) {
    @include triangle($color, $size, $orientation: 'north');
}

// North-East orientation
@mixin triangle-north-east($color: $color-primary-white, $size: 150px) {
    @include triangle($color, $size, $orientation: 'north-east');
}

// East orientation
@mixin triangle-east($color: $color-primary-white, $size: 150px) {
    @include triangle($color, $size, $orientation: 'east');
}

// South-East orientation
@mixin triangle-south-east($color: $color-primary-white, $size: 150px) {
    @include triangle($color, $size, $orientation: 'south-east');
}

// South orientation
@mixin triangle-south($color: $color-primary-white, $size: 150px) {
    @include triangle($color, $size, $orientation: 'south');
}

// South-West orientation
@mixin triangle-south-west($color: $color-primary-white, $size: 150px) {
    @include triangle($color, $size, $orientation: 'south-west');
}

// West orientation
@mixin triangle-west($color: $color-primary-white, $size: 150px) {
    @include triangle($color, $size, $orientation: 'west');
}

// North-West orientation
@mixin triangle-north-west($color: $color-primary-white, $size: 150px) {
    @include triangle($color, $size, $orientation: 'north-west');
}
