/* Scss includes instead of the dist ? */
@import '../../../../../node_modules/select2/dist/css/select2.css';

//@import '../../../../../node_modules/select2/src/scss/mixins/gradients';
//@import '../../../../../node_modules/select2/src/scss/core';
//@import '../../../../../node_modules/select2/src/scss/theme/default/layout';
//
//@import '../../../../../node_modules/select2/src/scss/single';
//@import '../../../../../node_modules/select2/src/scss/theme/default/single';
//
//@import '../../../../../node_modules/select2/src/scss/multiple';
//@import '../../../../../node_modules/select2/src/scss/theme/default/multiple';
//
//@import '../../../../../node_modules/select2/src/scss/dropdown';


.select2-dropdown-single{
  border: 1px solid #000;
  padding: 5px 0;
}
.select2 .selection .select2-selection--single, .select2-container--default .select2-search--dropdown .select2-search__field {
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  height: 2.05rem;
}

.select2-container--default .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
}

.select2-results__option {
  color: #66bb6a;
  padding: 8px 16px;
  font-size: 16px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #eee !important;
  color: #66bb6a !important;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #e1e1e1 !important;
}

.select2-dropdown {
  border: none !important;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}

.select2-container--default .select2-results__option[role=group] .select2-results__group {
  background-color: #333333;
  color: #fff;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0 !important;
}

.select2-container .select2-search--inline .select2-search__field:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}

.select2-container .select2-selection--multiple {
  min-height: 2.05rem !important;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #ddd !important;
  color: rgba(0,0,0,0.26);
  border-bottom: 1px dotted rgba(0,0,0,0.26);
}

.select2-container--open .select2-dropdown--above {
  border:1px solid #000 !important;
  left:-1px;
  border-radius:0px;
}

.select2-container--open .select2-dropdown--above, .select2-container--open .select2-dropdown--below{

  box-shadow:0 7px 47px rgba(0,0,0,.5) !important;

}