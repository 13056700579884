// variables
$color-primary-dark: #fff;
$color-primary-white: #fff;
$color-primary-accent: #fff;
$color-primary-transparent: transparent;
$color-primary-tint-2: #002e47;
$color-primary-tint-3: #587625;
$color-primary-tint-4: #587625;
$color-primary-tint-5: #fff;
$color-primary-tint-6: #fff;
$color-primary-error: red;
$form-fields-border-color: #fff;
$jack-main-color: rgba(114, 176, 189, 1);
// --------------------------------------------------------------------------
// Butons:                                 scss/components/_buttons.scss
// --------------------------------------------------------------------------

// .btn
$btn-default-bg-color:                     $color-primary-tint-6;
$btn-default-bg-color-focus:               $color-primary-tint-6;
$btn-default-bg-color-hover:               $color-primary-tint-6;
$btn-default-font-color:                   $color-primary-white;

// .btn.btn-light
$btn-light-color:                          $color-primary-white;
$btn-light-bg-color:                       $color-primary-tint-6;
$btn-light-bg-color-focus:                 $color-primary-tint-6;
$btn-light-bg-color-hover:                 $color-primary-tint-6;

// .btn.btn-light.btn-arrow
$btn-light-arrow-angle-color:              $color-primary-white;
$btn-light-arrow-seperator-color:          $color-primary-tint-3;

$btn-dark-arrow-angle-color:               $color-primary-white;
$btn-dark-arrow-seperator-color:           $color-primary-tint-3;
$btn-dark-arrow-color:                     $color-primary-white;

$btn-light-btn-border-border-color:        $color-primary-tint-6;
$btn-light-btn-border-bg-color:            transparent;
$btn-light-btn-border-text-color:          $color-primary-tint-6;

// .btn.btn-dark
$btn-dark-bg-color:                        $color-primary-tint-5;
$btn-dark-bg-color-focus:                  $color-primary-tint-5;
$btn-dark-bg-color-hover:                  $color-primary-tint-5;

// .btn.btn-dark-readmore
$btn-dark-read-more-color:                     $color-primary-dark;
$btn-dark-read-more-bg-color:                  $color-primary-white;
$btn-dark-read-more-bg-color-focus:            $color-primary-white;
$btn-dark-read-more-bg-color-hover:            $color-primary-white;

// .btn.btn-dark-read-more.btn-border
$btn-dark-read-more-btn-border-border-color:   $color-primary-dark;
$btn-dark-read-more-btn-border-bg-color:       transparent;
$btn-dark-read-more-btn-border-text-color:     $color-primary-dark;

$btn-dark-btn-border-border-color:         $color-primary-tint-5;
$btn-dark-btn-border-bg-color:             transparent;
$btn-dark-btn-border-text-color:           $color-primary-tint-5;

// .btn.btn-dark
$btn-dark-green-color:                     $color-primary-tint-4;
$btn-dark-green-bg-color:                  $color-primary-accent;
$btn-dark-green-bg-color-focus:            $color-primary-accent;
$btn-dark-green-bg-color-hover:            $color-primary-accent;

// .btn.btn-dark
$btn-dark-read-more-color:                 $color-primary-tint-3;
$btn-dark-read-more-bg-color:              $color-primary-accent;
$btn-dark-read-more-bg-color-focus:        $color-primary-accent;
$btn-dark-read-more-bg-color-hover:        $color-primary-accent;

// .btn.btn-dark-green.btn-border
$btn-dark-green-btn-border-border-color:   $color-primary-accent;
$btn-dark-green-btn-border-bg-color:       $color-primary-white;
$btn-dark-green-btn-border-text-color:     $color-primary-accent;

$btn-read-more-arrow-angle-color:          $color-primary-dark;
$btn-read-more-arrow-seperator-color:      $color-primary-tint-3;

// .btn.btn-dark-green.btn-border
$btn-arrow-angle-color:                    $color-primary-white;
$btn-arrow-seperator-color:                $color-primary-white;

// .btn.btn-check
$btn-checkbox-seperator-color:             $color-primary-white;
$btn-checkbox-background-color:            $color-primary-white;
$btn-checkbox-check-color:                 $color-primary-tint-5;
$btn-checkbox-check-bg-color:              $color-primary-tint-2;
$btn-checkbox-check-border-color:          $color-primary-tint-3;

$input-text-color:              $color-primary-tint-2;

// .btn.btn-dark read more
$btn-dark-read-more-color:                     $color-primary-dark;
$btn-dark-read-more-bg-color:                  $color-primary-white;
$btn-dark-read-more-bg-color-focus:            $color-primary-white;
$btn-dark-read-more-bg-color-hover:            $color-primary-white;

// .btn.btn-dark-read-more.btn-border
$btn-dark-read-more-btn-border-border-color:   $color-primary-dark;
$btn-dark-read-more-btn-border-bg-color:       transparent;
$btn-dark-read-more-btn-border-text-color:     $color-primary-dark;

$btn-light-read-more-color:                 $color-primary-white;
$btn-light-read-more-bg-color:              $color-primary-dark;
$btn-light-read-more-bg-color-focus:        $color-primary-dark;
$btn-light-read-more-bg-color-hover:        $color-primary-dark;

// .btn.btn-dark-read-more.btn-border
$btn-arrow-angle-color:                    $color-primary-white;
$btn-arrow-seperator-color:                $color-primary-white;
$btn-light-arrow-angle-color:              $color-primary-white;
$btn-light-arrow-seperator-color:          $color-primary-tint-3;
$btn-read-more-arrow-angle-color:          $color-primary-white;
$btn-read-more-arrow-seperator-color:      $color-primary-tint-3;

$btn-read-more-arrow-angle-color:          $color-primary-dark;
$btn-read-more-arrow-seperator-color:      $color-primary-dark;

// .btn.btn-alternative-readmore
$btn-alternative-color:                     $color-primary-white;
$btn-alternative-bg-color:                  $color-primary-dark;
$btn-alternative-bg-color-focus:            $color-primary-dark;
$btn-alternative-bg-color-hover:            $color-primary-dark;

$btn-transparent-bg-color:                 $color-primary-transparent;
$btn-transparent-color:                    $color-primary-white;
$btn-transparent-border-color:             $color-primary-white;

$btn-product-color:                        $color-primary-dark;
$btn-product-border-color:                 $color-primary-dark;

// .btn-border | Buttons with border
$btn-border-width:                         1px;
// --------------------------------------------------------------------------
// Forms                                   scss/components/_forms.scss
// --------------------------------------------------------------------------
$form-border-color:                        $color-primary-dark;
$form-input-text-color:                    $color-primary-dark;
$form-fields-border-color:                 $color-primary-dark;
$form-fields-valid-border-color:           $color-primary-dark;
$form-fields-invalid-border-color:         $color-primary-error;
$form-fields-focus-border-color:           $color-primary-tint-4;
$form-fields-focus-label-color:            $color-primary-tint-4;
$form-field-checkbox-border-color:         $color-primary-tint-4;
$form-infobox-bg-color:                    $color-primary-dark;
$form-infobox-text-color:                  $color-primary-white;
$form-infobox-icon-color:                  $color-primary-white;
$form-information-bg-color:                $color-primary-dark;
$form-information-text-color:              $color-primary-white;
$form-information-booking-bg-color:        $color-primary-accent;
$form-information-booking-text-color:      $color-primary-white;
$blanco-profile-image-url:                 "/images/james-blanco-profile-image.png";
// --------------------------------------------------------------------------
// Infobox Forms                            scss/components/_infobox-form.scss
// --------------------------------------------------------------------------
$infobox-background-color:                  $color-primary-tint-2;
$infobox-form-background-color:             $color-primary-white;
$infobox-form-border-color:                 $color-primary-dark;
// --------------------------------------------------------------------------
// Section - Infobox:                      scss/sections/_infobox.scss
// --------------------------------------------------------------------------
$infobox-trianble-1-bg-color:              $color-primary-dark;
$infobox-trianble-2-bg-color:              $color-primary-tint-3;
// ----------------------------------------------------------------------------
// Cookie Consent banner                      css/components/_ccbar.scss
// ----------------------------------------------------------------------------
$cc-banner-bg-color:$color-primary-white;
$cc-banner-color:$color-primary-dark;
$cc-banner-border-color:$color-primary-dark;
$cc-banner-text-color: $color-primary-dark;
$cc-banner-button-bg-color: $color-primary-dark;
$cc-banner-button-color:$color-primary-white;
// ----------------------------------------------------------------------------
// Home specific
// ----------------------------------------------------------------------------
$main-color: $color-primary-tint-4;
$main-color-tint1: #EDEDED;
$main-white: #fff;

// imports
@import '../includes/materialize';            // Include the main Materialize styles.
//@import '../includes/variables';              // Include middle layer variables (mainly colors)
@import '../includes/mixins';                 // Include custom mixins
//@import '../includes/utils';                  // Include utility classes like background colors
//@import '../includes/svg-icons';              // Include SVG icon styles
@import '../includes/triangles';              // Include the triagle mixin system
/////
@import '../includes/font-awesome';            // Include the main Materialize styles.
@import '../includes/materialize-social';      // Include the main Materialize styles.
// Get all component specific styles
@import '../components/forms';                // Main form styling
@import '../components/buttons';              // Button styling
@import "../components/ccbar";
@import "../components/select2";
// Get page specific styling
@import "../components/infobox-form";                // Custom login-page specific styles
// Get al specific section styling
@import '../sections/infobox';                // Infobox section styling
// select2
@import '../includes/select2';

body {
    height: 100%;
    margin: 0;
    // slider
    .owl-carousel {
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        z-index: initial;
        div:not(.owl-controls) {
            height: 100%;
        }
        .owl-nav,
        .owl-dots {
            display: none;
        }
        .owl-slide {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    // Content

    .smsForm{
        position: fixed;
        z-index: 2;
        width: 100%;
        background:$color-primary-tint-2;
        background-position: center;
        bottom:0;
        margin-top:-250px;

        .container{
            width:90%;
            padding: 10px 0;
        }

        .row{
            margin-bottom:0;
        }

        @media only screen and (min-width: 1126px){
            background:fixed;
            margin:100px auto;
            position: absolute;
            right: 10%;
            top:40%;
            width: 20%;
            transform: translateY(-50%);
        }
        @media only screen and (max-width: 1126px){
            .close{
                position:absolute;
                right:10px;
                top:5px;
                font-size:20px;
                color:$main-white;
            }
        }

        form[name="send_sms_form"]{
            label[for="send_sms_form_phone"]{
                &.active{
                    color:$main-white;
                }
                padding-left:60px;
            }

            @media only screen and (max-width: 1126px){
                .input-field{
                    width:80%;
                    float:left;
                }

                .input-field + div{
                    float:left;
                }
            }

            .intl-tel-input{
                margin-bottom:0;
            }

            @media only screen and (max-width: 768px){
                .input-field{
                    width:70%;
                }
            }

            @media only screen and (max-width: 425px){
                .input-field{
                    width:60%;
                }
            }
        }

        h3{
            color: $main-white;
            line-height:150%;
            b{
                display:inline-block;
                margin-bottom:0.5em;
            }

            @media only screen and (max-width: 1126px){
                font-size:1rem;
                margin:0;
            }
        }
        form{
            input[type=text]{
                background: $main-white;
                color:$input-text-color;
                padding-left: 5%;
                padding-right:5%;
                border-bottom:0;
            }

            button[type=submit]{
                height: 42px;
            }

            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $input-text-color;
                opacity: 1; /* Firefox */
            }

            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $input-text-color;
            }

            ::-ms-input-placeholder { /* Microsoft Edge */
                color: $input-text-color;
            }

            #send_sms_form_save{
                background: $main-color;
                @media only screen and (max-width: 1126px){
                    line-height:0;
                }
            }
        }
    }

    .forms {
        @media only screen and (max-width: 1126px){
            padding-bottom: 250px;
        }
        &.c {
            position: absolute;
            z-index: 2;
            top: 50%;
            right: 0;
            left: 3%;
            width: 30%;
            transform: translateY(-50%);
            // navigation
            .flex-container {
                display: flex;
                align-items: center;
                #menuItems {
                    order: 1;
                    background-color: $main-color;
                    padding: 10px 0;
                    height: fit-content;
                    margin-right: 40px;
                    .menu-item {
                        display: inline-block;
                        padding: 5px 35px;
                        line-height: 15px;
                        color: $main-white;
                        &.support {
                            cursor: pointer;
                        }
                        &.borders {
                            border-left: 1px solid $main-white;
                            border-right: 1px solid $main-white;
                        }
                        a {
                            color: $main-white;
                        }
                    }
                }
                #menuLogo {
                    order: 2;
                    img {
                        width: 117px;
                    }
                }

            }
            // signIn/register blocks
            .signIn,
            .register,
            .requestP {
                &.focus {
                    background-color:$main-white;
                    transition: background-color 1s ease;
                }
                background-color: $main-color-tint1;
                transition: background-color 1s ease;
                padding: 30px;
                box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
                min-width: 280px;
                z-index: 2;
                input {
                    border: 1px solid;
                    margin-bottom: 0;
                    padding: 0 25px !important;
                    box-sizing: border-box;
                }
                h3 {
                    margin-top: 0;
                }
                .form-checkbox-container {
                    margin-top: 20px;
                }
                .login-help {
                    margin-top: 15px;
                    a {
                        text-decoration: underline;
                        color: $color-primary-tint-2;
                    }
                }
                .no-margin-bottom {
                    margin-bottom: 0;
                }
                .btn {
                    background-color: $main-color;
                    border: 1px solid $main-color-tint1;
                    &.btn-arrow {
                        &::before {
                            content: none;
                        }
                        &::after {
                            content: none;
                        }
                        i {
                            border-left: 5px solid $main-color-tint1;
                        }
                    }
                }
            }
            .register {
                input {
                    border-bottom: 1px solid;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                }
            }
            .signIn,
            .requestP {
                label {
                    &.indent.active {
                        background-color: $main-white;
                        height: 20px;
                    }
                }
            }
            .requestP {
                display: none;
            }
            @media only screen and (max-height: 625px) {
                top: 10%;
                transform: initial;
            }
        }
    }
    .rmenu {
        z-index: 2;
        position: relative;
        float: right;
        height: 100vh;
        padding: 50px 30px 0;
        width: 573px;
        @media only screen and (max-width: 1125px){
            float: none;
            height: auto;
            width: 100%;
            margin: 0 auto 0;
        }
        .flex-container {
            display: flex;
            align-items: center;
            #menuItems {
                order: 1;
                background-color: $main-color;
                padding: 10px 0;
                height: fit-content;
                margin-right: 40px;
                .menu-item {
                    display: inline-block;
                    padding: 5px 35px;
                    line-height: 15px;
                    color: $main-white;
                    &.support {
                        cursor: pointer;
                    }
                    &.borders {
                        border-left: 1px solid $main-white;
                        border-right: 1px solid $main-white;
                    }
                    a {
                        color: $main-white;
                    }
                }
            }
            #menuLogo {
                order: 2;
                @media only screen and (max-width: 1125px){
                    margin-bottom: 20px;
                }
                img {
                    width: 117px;
                }
            }
        }
        #faq,
        .closeOverlay {
            display: none;
        }
        &.support {
            background-color: $main-color;
            .closeOverlay {
                background-color: $main-white;
                width: 36px;
                height: 36px;
                padding: 10px;
                position: absolute;
                top: 80px;
                cursor: pointer;
                left: -19px;
                z-index: 4;
                display: block;
            }
            #faq {
                display: block;
                color: $main-white;
                .faq_collapsible {
                    border: none;
                    box-shadow: none;
                    li {
                        padding-right: 45px;
                        .collapsible-header {
                            background-color: inherit;
                            border: none;
                            font-weight: 600;
                            padding-left:36px;
                            position: relative;
                            &.active i {
                                transform: rotate(225deg);
                                top: 23px;
                            }
                            i {
                                position: absolute;
                                left: 0;
                                transform: rotate(45deg);
                                font-size: 1rem;
                                color: $color-primary-tint-3;
                            }
                        }
                        .collapsible-body {
                            border: none;
                            padding: 0 0 0 36px;
                            a {
                                color: $main-white;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    &.plusjack {
        .smsForm{
            form{
                #send_sms_form_save{
                    background: $jack-main-color;
                }
            }
        }
        .forms {
            &.c {
                .flex-container {
                    #menuItems {
                        background-color: $jack-main-color;
                    }
                }
                // signIn/register blocks
                .signIn,
                .register,
                .requestP {
                    .btn {
                        background-color: $jack-main-color;
                        border: 1px solid $jack-main-color;
                        &.btn-arrow {
                            i {
                                border-left: 5px solid $jack-main-color;
                            }
                        }
                    }
                }
            }
        }
        .rmenu {
            .flex-container {
                #menuItems {
                    background-color: $jack-main-color;
                }
            }
            &.support {
                background-color: $jack-main-color;
                #faq {
                    .faq_collapsible {
                        li {
                            .collapsible-header {
                                i {
                                    color: $jack-main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        [type=checkbox]:checked+label:before {
            border-right: 2px solid $jack-main-color;
            border-bottom: 2px solid $jack-main-color;
            border-top: 2px solid transparent;
            border-left: 2px solid transparent;
        }
        [type=checkbox]+label:before, [type=checkbox]:not(.filled-in)+label:after{
            border: 1px solid $jack-main-color;
        }
        input:not([type]):focus:not([readonly]),
        input[type=date]:not(.browser-default):focus:not([readonly]),
        input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
        input[type=datetime]:not(.browser-default):focus:not([readonly]),
        input[type=email]:not(.browser-default):focus:not([readonly]),
        input[type=number]:not(.browser-default):focus:not([readonly]),
        input[type=password]:not(.browser-default):focus:not([readonly]),
        input[type=search]:not(.browser-default):focus:not([readonly]),
        input[type=tel]:not(.browser-default):focus:not([readonly]),
        input[type=text]:not(.browser-default):focus:not([readonly]),
        input[type=time]:not(.browser-default):focus:not([readonly]),
        input[type=url]:not(.browser-default):focus:not([readonly]),
        textarea.materialize-textarea:focus:not([readonly]) {
            border-bottom: 1px solid $jack-main-color;
            box-shadow: 0 1px 0 0 $jack-main-color;
        }
    }
}
html {
    height: 100%;
}
@media only screen and (max-width: 1150px){
    body {
        .forms {
            &.c {
                width: 40%;
            }
        }
    }
}
@media only screen and (max-width: 850px){
    body {
        .forms {
            &.c {
                width: 60%;
            }
        }
    }
}
@media only screen and (max-width: 767px){
    body {
        background-color: $main-color !important;
        &.plusjack {
            background-color: $jack-main-color !important;
        }
        .owl-carousel {
            &.owl-loaded {
                display: none;
            }
        }
    }
}
@media only screen and (max-width: 1125px){
    body {
        .rmenu {
            position: relative;
            .flex-container {
                flex-direction: column;
                float: none !important;
                #menuItems {
                    order: 2;
                    margin-right: 0;
                    .menu-item {
                        padding: 5px 15px;
                    }
                }
                #menuLogo {
                    order: 1;
                }
            }
            &.support {
                width: 100%;
                height: 100vh;
                margin-top: 0;
                padding-top: 100px;
                position: absolute;
                z-index: 3;
                .closeOverlay {
                    right: 3%;
                    left: initial;
                }
            }
        }
        .forms {
            &.c {
                top: 40px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                margin-bottom: 5%;
                position: relative;
            }
        }
    }
}

@media only screen and (max-width: 550px){
    body {
        .forms {
            &.c {
                width: 90%;
                left: 5%;
                -webkit-transform: none;
                transform: none;
                .signIn,
                .register,
                .requestP {
                    padding: 15px;
                }
            }
        }
    }
}
@media only screen and (max-height: 715px){
    body {
        .forms {
            &.c {
                .register {
                    .select-wrapper {
                        ul.dropdown-content {
                            max-height: 44.5vh !important;
                            top: calc(-44.5vh + 35px) !important;
                        }
                    }
                }
            }
        }
    }
}
