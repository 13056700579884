body .cc_banner-wrapper {
    z-index:999999;
    .cc_banner{
        background-color:$cc-banner-bg-color;
        color:$cc-banner-color;
        border-top: 1px solid $cc-banner-border-color;
        a{
            color:$cc-banner-text-color;  
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }      
        }

        .cc_btn{
            background-color:$cc-banner-button-bg-color;
            color:$cc-banner-button-color;  
            text-decoration: none;
            &:hover{
                opacity: 0.8;
            }
        }
    }
}