.infobox {
    background-color: $infobox-background-color;

    .password-reset, .reconfirm-mail{
        background: $infobox-form-background-color;
        padding:2.933rem 4rem 4rem 2.933rem;
    }

    &.flex{
        @media #{$extra-large-and-up} {
            .row{
                display: flex;
            }
            .col:first-child{
                height:auto;
            }
        }
    }
    form{
        background: $infobox-form-background-color;
        border:none;
        padding:2.933rem 4rem 4rem 2.933rem;

        h3,h5{
            margin-top:0;
            color:$form-input-text-color;

        }
        .input-field{
            width:  100%;
        }

        .col{
            margin-bottom: 2rem;
        }

        .col:last-child{
            margin-bottom:0;
        }

        .row:last-child{
            margin-bottom: 0;
        }


        @media #{$extra-large-and-up} {
            .col{
                margin-bottom: 0;
            }
            .col:first-child{
                border-right:2px solid  $infobox-form-border-color;
                height:100%;
            }

            .input-field{
                width:  20rem;
            }
        }
        @media screen and (min-width:1500px){
            .input-field{
                width:  23.87rem
            }
        }


        .input-field .input-field{
            width: 100%;
        }

        .form-checkbox-container{
            margin-top:1rem;
        }
        &[name='service_booking'] {
            .single-col {
                .col:first-child{
                    border-right: none;
                }
                .input-field {
                    width: 100%;
                }
            }
        }
    }

    form.registration{
        .file-field{
            padding-left: 0;
            position: initial;
        }
        .file-field:before{
            background-image: url($blanco-profile-image-url);
            background-repeat: no-repeat;
            content: "";
            padding-left: 50px;
            width: 81px;
            height: 81px;
            display: block;
            position: absolute;
            background-size: contain;
            top:0;
            left: 0;
        }

        .vich-image{
            padding-top:100px;

            & + label{
                font-size: 1.1rem;
                transform:none;
                top:25px;
                left:100px;
                @media screen and (min-width:1500px){
                    font-size:1.333rem;
                }

            }
            .btn{
                font-weight: 400;
                font-size: 1.2rem;
                height:2.333rem;
                line-height: 2.333rem;
                float: none;
                span{
                    padding: 0 0.7rem;
                }
            }

            .file-path-wrapper{
                width: auto;
                padding-left: 0;
                border: none;

                input{
                    border: none;
                    text-indent: 0;
                    height: 27px;
                    box-shadow: none;
                }
            }
        }

        .pullIn{
            margin-top: -13px;
            padding-left: 0;
        }

        @media #{$medium-and-up} {
            .file-field{
                padding-left:100px;
            }
            .vich-image{
                padding-top:30px;
                & + label{
                    top:0;
                }
                >a{
                    display: none;
                }
            }
            .pullIn{
                padding-left: 100px;
            }
        }

        #subaccount-toggle{
            color:$form-input-text-color;
            text-decoration: underline;
            // padding-left:25px;
            position: relative;
            margin-top:1.333rem;
            display: block;
            font-size: 1.2rem;
            &:before{
                // content:"";
                // background-image:url(/images/co-profile-info-button.png);
                // width:18px;
                // height:18px;
                // position: absolute;
                // left: 0;
                // top: 50%;
                // margin-top: -7px;
            }
        }

        .intl-tel-input{
            margin-bottom: 0;
        }

    }

    .login-help{
        margin-top: 15px;
        a{
            text-decoration: underline;
            color:$form-input-text-color;
        }
    }
    .form-success{
        color:$color-primary-dark;
    }
}

#subaccount-form{
    transition: all .5s linear;
    overflow:hidden;
    max-height: 1000px;
    display:block;

    &.hidden{
        max-height: 0;
    }
}

.infobox.fullwidth-form form .input-field {
    width:100%;
    .select-wrapper+label {
        top: -29px;
    }
}

.service-detail .infobox {
    div.input-field.required:before {
        content: none;
    }

    div.input-field.required {

        &>label:before {
            content: '* ';
        }
    }
}
